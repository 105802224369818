const langs = [
    {
        id: 'tm',
        lang: 'Türkmen'
    },
    {
        id: 'ru',
        lang: 'Русский'
    },
    {
        id: 'en',
        lang: 'English'
    },
]
export default langs